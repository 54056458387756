<template>
  <div class="cache-reset">
    <AppText size="15px" class="font-medium" variant="div" mb="17px">
      Reset cache for
    </AppText>

    <FRadioButton
      v-slot="option"
      v-model="val"
      :options="vals"
      height="36px"
      style="margin-bottom: 10px;"
    >
      <div class="item">
        <AppIcon :name="option.logo" size="18px" is-img-tag />
        <AppText class="font-medium">
          {{ option.text }}
        </AppText>
      </div>
    </FRadioButton>

    <transition name="show">
      <div v-if="val === 'uid'">
        <ActionForm
          :request-fields="requestFields"
          :validation-schema="validationSchema"
          :response-errors="fieldsErrors"
          @validate="onValidate"
        >
          <template #default="{ errors, action }">
            <FInput
              v-if="val === 'uid'"
              v-model="requestFields.uid"
              placeholder="Merchant UID"
              :validation-error="errors.uid"
              has-prefix
              :maxlength="25"
              :is-full-width="false"
              style="width: 428px;"
            >
              <template #prefix>
                <AppIcon
                  name="uid-circle"
                  size="18px"
                  is-img-tag
                />
              </template>
            </FInput>

            <AppText
              size="13px"
              color="var(--color-black-04)"
              :line-height="1.35"
              variant="div"
              mt="20px"
              mb="20px"
            >
              This function will reset local cache for all sessions linked to the UID above
            </AppText>

            <FButton size="small" type="black" @click="action">
              Reset cache
            </FButton>
          </template>
        </ActionForm>
      </div>

      <div v-else>
        <AppText
          size="13px"
          color="var(--color-black-04)"
          :line-height="1.35"
          variant="div"
          mb="20px"
        >
          This will reset cache for <strong>all users</strong>
        </AppText>

        <FButton size="small" type="black" @click="onGenerate">
          Reset cache
        </FButton>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';

import { generateNewFeVersion } from '@/api/admin';
import { ActionForm, useValidation, yup } from '@/validation';
import { uidAsSuperadmin } from '@/validation/rules';

const val = ref('uid');

const vals = [
  {
    text: 'Specific UID',
    value: 'uid',
    logo: 'user',
  },
  {
    text: 'All merchants',
    value: 'all',
    logo: 'users-stroke',
  },
];

const fields = {
  uid: '',
};

const { initState, fieldsErrors } = useValidation();
const { requestFields } = initState(fields);

const validationSchema = computed(() => yup.object().shape({
  uid: uidAsSuperadmin,
}));

const onReset = () => {
  fieldsErrors.value = {};
  requestFields.uid = '';
  val.value = 'uid';
};

const onValidate = async () => {
  await generateNewFeVersion(requestFields.uid);
  onReset();
};

const onGenerate = async () => {
  await generateNewFeVersion();
  onReset();
};
</script>

<style lang="scss" scoped>
.cache-reset {
  background-color: var(--color-F7F7F7);
  padding: 30px 40px 40px 40px;

  .item {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  :deep(.f-input) {
    margin-bottom: 20px;
  }
}
</style>
