import { useFetch } from '@/api';
import { useAdminStore } from '@/store';
import { useLang } from '@/composables/useLang';
import useNotifications from '@/composables/useNotifications';

import { clearState } from '../auth';

export const adminLogout = async ({ pushToHome, useLogoutEndpoint = true } = {
  pushToHome: false,
  useLogoutEndpoint: true,
}) => {
  const { axiosWrapper } = useFetch();

  if (!useLogoutEndpoint) {
    clearState({ pushToHome });
    return;
  }

  const { isSuccess } = await axiosWrapper({
    type: 'post',
    url: '/auth/logout',
    useRefreshToken: true,
  });

  if (isSuccess) {
    clearState({ pushToHome });
  }
};

export const readAdminProfile = async (params = {}) => {
  const { axiosWrapper } = useFetch();
  const { initProfile } = useAdminStore();
  const { setLanguage } = useLang();

  const { data, isSuccess } = await axiosWrapper({
    type: 'get',
    url: '/settings/admin/profile',
    params,
  });

  if (isSuccess) {
    initProfile(data);
    setLanguage(data.language);
  }
};

export const generateNewFeVersion = async (targetUid) => {
  const { axiosWrapper, successHandler } = useFetch();

  const { isSuccess, errorObject } = await axiosWrapper({
    type: 'patch',
    url: '/settings/admin/fe-version',
    params: { targetUid },
  });

  if (isSuccess) {
    successHandler('Cache reset successful');
  } else {
    const { addNotification } = useNotifications();

    if (errorObject.message === 'MERCHANT_DOES_NOT_EXIST') {
      addNotification({ text: 'Merchant does not exist!', config: { color: 'error' } });
    } else {
      addNotification({ text: 'Cache reset failed!', config: { color: 'error' } });
    }
  }

  return isSuccess;
};

export default {
  adminLogout,
  readAdminProfile,
};
